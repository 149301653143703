export const FirebaseConfig = {
	"projectId": "filride-app-16",
	"appId": "1:371948850680:web:da67b1e4a06fa1f11c9a0d",
	"databaseURL": "https://filride-app-16-default-rtdb.firebaseio.com",
	"storageBucket": "filride-app-16.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAlWT0TRGJR50JzWjU-Kx_blM9ownpoYLI",
	"authDomain": "filride-app-16.firebaseapp.com",
	"messagingSenderId": "371948850680",
	"measurementId": "G-8M3BY05CQT"
};