import React, { useState, useEffect} from 'react';
import { Grid, Typography, Button, Box, Tooltip, } from '@mui/material';
import backgroundImage from '../assets/img/background.jpg';
import useStyles from '../styles/styles';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import {calcEst, showEst, optionsRequired, FONT_FAMILY} from '../common/sharedFunctions';
import {colors as col} from "../components/Theme/WebTheme"

const Hero = (props) => {
  const classes = useStyles();
  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const role = props.role;
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector(state => state.auth);

  useEffect(()=>{
    if(auth.profile && auth.profile.uid){
      setLoggedIn(true);
    }else{
      setLoggedIn(false);
    }
  },[auth.profile]);

  return (
    <Box 
    className={classes.heroBox} 
    sx={{ 
      backgroundImage: `url(${backgroundImage})`, 
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column', 
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: { xs: '16px', sm: '20px' },  
      padding: '20px',
    }} 
    style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }}
  >
    <Grid container spacing={12} className={classes.gridContainer}>
      <Grid item xs={12} md={8} lg={7} xl={6}>
        <Typography 
          variant="h1" 
          fontWeight={600} 
          className={classes.titleMain} 
          sx={{
            color: col.CAR_BOX_ODD, 
            fontFamily: FONT_FAMILY, 
            lineHeight: '1.2', 
            fontSize: { xs: '2.5rem', sm: '4rem', md: '5rem', lg: '6rem' }  
          }}>
          {t('book_your_title')}
        </Typography>

        <Typography 
          variant="h6" 
          sx={{
            color: 'white', 
            fontFamily: FONT_FAMILY, 
            paddingInline: "30px", 
            fontSize: { xs: '1.25rem', sm: '1.75rem', md: '2rem' }  
          }}>
          {t('about_us_content2')}
        </Typography>

        <Typography 
          variant="h6" 
          sx={{
            color: '#F5B03C', 
            textAlign: "center", 
            fontFamily: FONT_FAMILY, 
            fontSize: { xs: '1rem', sm: '1.25rem' }  
          }}>
          {t('content_business')}
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: "space-between", marginTop: "1.5rem" }}>  
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px' },   
              fontFamily: FONT_FAMILY, 
              backgroundColor: 'white', 
              color: '#15143B',
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              display: 'inline-block',
              fontWeight: 'bold',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'white', 
                color: '#15143B',  
              }
            }}
            onClick={(e) => { 
              e.preventDefault();
              if (loggedIn) {
                navigate(((role && role === 'driver') || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin'))) ? '/bookings' : '/addbookings');
              } else {
                navigate('/login');
              }
            }}
          >
            {role === 'driver' || (calcEst && !showEst && !optionsRequired && (role === 'admin' || role === 'fleetadmin')) ? t('info') : t('book_your_ride_menu')}
          </Button>

          <Tooltip title={t("corporate_registration_txt")} arrow>
            <Button
              variant="contained"
              sx={{  
                fontSize: { xs: '14px', sm: '18px', md: '20px' },   
                fontFamily: FONT_FAMILY, 
                backgroundColor: 'white', 
                color: '#15143B',
                textOverflow: 'ellipsis', 
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                display: 'inline-block',
                fontWeight: 'bold',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'white', 
                  color: '#15143B',
                }
              }}
              onClick={() => window.open('https://www.atlride.com/corporat', '_blank')}
            >
              {t('corporate_registration')}
            </Button>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  </Box>
  );
};

export default Hero;